/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import cx from 'classnames';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import { CloseIcon } from '../../icons/Close';

interface MobileMenuOverlayProps {
  isOpen: boolean;
  label: string;
  menuItems: Array<SbBlokData>;
  onClose: () => void;
}

const MobileMenuOverlay = ({
  isOpen,
  onClose,
  menuItems,
  label,
}: MobileMenuOverlayProps) => {
  React.useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
  }, [isOpen]);

  return (
    <nav
      className={cx('relative z-[100] lg:hidden', {
        block: isOpen,
        hidden: !isOpen,
      })}
    >
      <div className="fixed inset-0 bg-black bg-opacity-25" />
      <div className="fixed inset-0 overflow-y-auto font-worksans">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <div className="absolute right-0 top-0 bottom-0 w-full max-w-xl transform overflow-hidden bg-white text-left shadow-xl transition-all overflow-y-auto h-full">
            <div className="flex justify-between items-center py-2 px-4 sm:p-5 sm:pl-6 border-b border-GSparchment/200 sticky z-10 top-0 bg-white">
              <span className="text-base sm:text-lg font-bold text-GSdeep/500">
                {label}
              </span>
              <button
                type="button"
                className="select-none border-none focus:outline-none"
                onClick={onClose}
              >
                <CloseIcon />
              </button>
            </div>
            {menuItems && menuItems.length !== 0 && (
              <div className="flex flex-col flex-wrap py-6 px-4 gap-y-2 items-center">
                {menuItems?.map((nestedBlok: SbBlokData) => (
                  <StoryblokComponent
                    blok={nestedBlok}
                    key={nestedBlok._uid}
                    onClick={onClose}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MobileMenuOverlay;
