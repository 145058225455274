import { SbBlokData, storyblokEditable } from '@storyblok/react';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Navigation } from 'swiper/modules';
import { LazyMotion, m } from 'framer-motion';

import 'swiper/css';
import 'swiper/css/effect-cards';

import TitleBlok, { TitleBlokProps } from '../Title/Title';
import PageContainer from '../../components/PageContainer/PageContainer';
import { TutorSlideBlokProps } from '../TutorSlide/TutorSlide';
import StoryblokImage from '../../components/StoryblokImage/StoryblokImage';

const loadFeatures = () =>
  import('../../helpers/framerFeatures').then((res) => res.default);

export interface TutorsSliderSectionBlokProps extends SbBlokData {
  id?: string;
  slides?: Array<TutorSlideBlokProps>;
  title?: Array<TitleBlokProps>;
}

export interface TutorsSliderSectionProps {
  blok: TutorsSliderSectionBlokProps;
}

const StarIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    fill="none"
    className={className}
  >
    <mask
      id="mask0_4080_15919"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="23"
      height="23"
    >
      <rect
        x="0.621094"
        y="0.741211"
        width="22.121"
        height="22.121"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_4080_15919)">
      <path
        d="M11.6813 16.6638L7.8562 18.9681C7.68722 19.0756 7.51056 19.1217 7.32621 19.1063C7.14187 19.091 6.98057 19.0295 6.84232 18.922C6.70406 18.8145 6.59653 18.68 6.51972 18.5187C6.44291 18.3574 6.42755 18.1769 6.47363 17.9772L7.48751 13.6222L4.10023 10.6957C3.94661 10.5575 3.8506 10.4 3.8122 10.2234C3.77379 10.0467 3.78531 9.87387 3.84676 9.70489C3.90821 9.53591 4.00038 9.39766 4.12327 9.29012C4.24617 9.18259 4.41515 9.11346 4.63021 9.08274L9.10051 8.69101L10.8287 4.5894C10.9055 4.40506 11.0246 4.2668 11.1859 4.17463C11.3472 4.08246 11.5123 4.03638 11.6813 4.03638C11.8503 4.03638 12.0154 4.08246 12.1767 4.17463C12.338 4.2668 12.4571 4.40506 12.5339 4.5894L14.2621 8.69101L18.7324 9.08274C18.9474 9.11346 19.1164 9.18259 19.2393 9.29012C19.3622 9.39766 19.4544 9.53591 19.5158 9.70489C19.5773 9.87387 19.5888 10.0467 19.5504 10.2234C19.512 10.4 19.416 10.5575 19.2624 10.6957L15.8751 13.6222L16.889 17.9772C16.935 18.1769 16.9197 18.3574 16.8429 18.5187C16.7661 18.68 16.6585 18.8145 16.5203 18.922C16.382 19.0295 16.2207 19.091 16.0364 19.1063C15.852 19.1217 15.6754 19.0756 15.5064 18.9681L11.6813 16.6638Z"
        fill="#FFD363"
      />
    </g>
  </svg>
);

const ArrowButton = ({ disabled }: { disabled?: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="55"
      viewBox="0 0 54 55"
      fill="none"
    >
      <circle
        cx="27"
        cy="27.6975"
        r="27"
        fill={disabled ? '#F8F8FA' : '#F0F1F4'}
      />
      <mask
        id="mask0_4080_15883"
        maskUnits="userSpaceOnUse"
        x="19"
        y="19"
        width="24"
        height="25"
      >
        <rect x="19" y="19.6975" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4080_15883)">
        <path
          d="M32.127 27.6458H20.75C20.5372 27.6458 20.359 27.5739 20.2155 27.4303C20.0718 27.2868 20 27.1086 20 26.8958C20 26.6829 20.0718 26.5048 20.2155 26.3613C20.359 26.2176 20.5372 26.1458 20.75 26.1458H32.127L26.9577 20.9765C26.8091 20.8278 26.7357 20.6538 26.7375 20.4545C26.7395 20.2552 26.818 20.0779 26.973 19.9228C27.1282 19.7779 27.3038 19.7029 27.5 19.6978C27.6962 19.6926 27.8718 19.7676 28.027 19.9228L34.3673 26.263C34.4609 26.3567 34.5269 26.4554 34.5652 26.5593C34.6037 26.6631 34.623 26.7753 34.623 26.8958C34.623 27.0163 34.6037 27.1284 34.5652 27.2323C34.5269 27.3361 34.4609 27.4348 34.3673 27.5285L28.027 33.8688C27.8885 34.0073 27.717 34.0781 27.5125 34.0813C27.308 34.0844 27.1282 34.0136 26.973 33.8688C26.818 33.7136 26.7405 33.5354 26.7405 33.3343C26.7405 33.1329 26.818 32.9547 26.973 32.7995L32.127 27.6458Z"
          fill={disabled ? '#DADBE5' : 'black'}
        />
      </g>
    </svg>
  );
};

const TutorsSliderSection = ({ blok }: TutorsSliderSectionProps) => {
  const { id, slides = [], title: [title] = [] } = blok;
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <section {...storyblokEditable(blok)} id={id} className="w-full">
      <PageContainer className="!py-10 sm:!py-12 lg:!py-16">
        <div className="w-full grid grid-cols-6 sm:grid-cols-12 gap-x-2 sm:gap-x-4 lg:gap-x-8 lg:gap-y-10 gap-y-10 sm:gap-y-14">
          {title && (
            <div className="col-span-full lg:col-span-10 lg:col-start-2">
              <TitleBlok blok={title} headingLevel="h2" theme="ideal" />
            </div>
          )}
          <div className="col-span-full lg:col-span-10 lg:col-start-2 w-full grid grid-flow-col grid-cols-6 sm:grid-cols-12 gap-y-6">
            <div className="order-last sm:order-first col-start-1 col-span-full sm:col-span-7 lg:col-span-6 flex flex-col justify-between sm:pr-8 z-10 bg-gradient-to-r from-95% from-GSbase/white gap-y-4">
              <LazyMotion features={loadFeatures}>
                <m.div
                  key={slides[currentSlide]?._uid}
                  initial={{
                    opacity: 0,
                    y: 50,
                  }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: {
                      duration: 0.5,
                    },
                  }}
                  viewport={{ once: true }}
                  className="flex flex-col gap-4"
                >
                  <div className="text-GSbase/black font-raleway text-2xl lg:text-[40px] lg:leading-[56px] font-bold">
                    {slides[currentSlide]?.quote}
                  </div>
                  <div className="text-GSbase/black font-worksans text-lg lg:text-[18px] lg:leading-[28px] font-normal">
                    {slides[currentSlide]?.description}
                  </div>
                  <div className="text-GSbase/black font-worksans font-bold text-sm">
                    {slides[currentSlide]?.name}
                  </div>
                </m.div>
              </LazyMotion>
              <div className="hidden sm:flex gap-8">
                <div
                  className={`swiper-button-prev-${blok._uid} w-14 h-14 cursor-pointer rotate-180 select-none`}
                >
                  <ArrowButton disabled={isFirstSlide} />
                </div>
                <div
                  className={`swiper-button-next-${blok._uid} w-14 h-14 cursor-pointer select-none`}
                >
                  <ArrowButton disabled={isLastSlide} />
                </div>
              </div>
            </div>
            <div className="col-span-full sm:col-span-5 lg:col-span-6 sm:col-start-8 lg:col-start-7 w-full sm:py-8 flex flex-col gap-y-6">
              <Swiper
                effect="cards"
                grabCursor
                centeredSlides
                modules={[EffectCards, Navigation]}
                cardsEffect={{
                  slideShadows: false,
                  rotate: false,
                  perSlideOffset: 16,
                }}
                navigation={{
                  nextEl: `.swiper-button-next-${blok._uid}`,
                  prevEl: `.swiper-button-prev-${blok._uid}`,
                }}
                onSlideChange={(swiper) => {
                  setIsFirstSlide(swiper.realIndex === 0);
                  setIsLastSlide(swiper.realIndex === slides.length - 1);
                  setCurrentSlide(swiper.realIndex);
                }}
                onInit={(swiper) => {
                  setIsFirstSlide(swiper.isBeginning);
                  setIsLastSlide(swiper.isEnd);
                }}
                className="min-w-0 w-full max-w-full overflow-hidden"
              >
                {slides.map((tutorSlideBlok) => (
                  <SwiperSlide key={tutorSlideBlok._uid}>
                    <div className="min-w-0 w-full h-64 lg:h-96 flex">
                      <div className="self-center mx-auto h-full relative">
                        <StoryblokImage
                          blok={tutorSlideBlok.image}
                          className="h-full w-full rounded-2xl overflow-hidden"
                        />
                        <div className="absolute left-5 bottom-5 sm:left-4 sm:bottom-4 lg:bottom-8 lg:left-6">
                          <div className=" flex flex-col gap-1 items-start">
                            <div className="font-raleway text-sm sm:text-xs lg:text-base leading-5 font-extrabold text-GSbase/white">
                              {tutorSlideBlok.name}
                            </div>
                            <div className="flex">
                              <StarIcon className="w-4 h-4 sm:w-3 sm:h-3 lg:w-5 lg:h-5" />
                              <StarIcon className="w-4 h-4 sm:w-3 sm:h-3 lg:w-5 lg:h-5" />
                              <StarIcon className="w-4 h-4 sm:w-3 sm:h-3 lg:w-5 lg:h-5" />
                              <StarIcon className="w-4 h-4 sm:w-3 sm:h-3 lg:w-5 lg:h-5" />
                              <StarIcon className="w-4 h-4 sm:w-3 sm:h-3 lg:w-5 lg:h-5" />
                            </div>
                            <div className="flex gap-1">
                              {tutorSlideBlok.topics.split(',').map((topic) => (
                                <div
                                  key={topic}
                                  className="px-5 py-1 lg:px-8 lg:py-2 bg-GSsteel/300 rounded-lg lg:rounded-xl max-w-min font-worksans text-xs sm:text-[8px] sm:leading-[10px] lg:text-sm lg:leading-4 font-semibold"
                                >
                                  {topic.trim()}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="flex sm:hidden gap-8 mx-auto">
                <div
                  className={`swiper-button-prev-${blok._uid} w-14 h-14 cursor-pointer rotate-180 select-none`}
                >
                  <ArrowButton disabled={isFirstSlide} />
                </div>
                <div
                  className={`swiper-button-next-${blok._uid} w-14 h-14 cursor-pointer select-none`}
                >
                  <ArrowButton disabled={isLastSlide} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </section>
  );
};

export default TutorsSliderSection;
