import { CookieValueTypes, getCookie, hasCookie } from 'cookies-next';

export const isDefinedNonEmptyStringCookie = (value: CookieValueTypes) => {
  if (typeof value === 'string') {
    if (value.trim() === '') {
      return false;
    }

    return true;
  }

  return false;
};

export const getLandingPageUrl = () => {
  const cookieName = 'landingPage';

  if (hasCookie(cookieName)) {
    const value = getCookie(cookieName);
    if (isDefinedNonEmptyStringCookie(value)) {
      return value?.toString();
    }
  }

  return '';
};

export const getReferrerPageUrl = () => {
  const cookieName = 'referring';

  if (hasCookie(cookieName)) {
    const value = getCookie(cookieName);
    if (isDefinedNonEmptyStringCookie(value)) {
      return value?.toString();
    }
  }

  return '';
};

export const getCustomSessionId = () =>
  getCookie('_custom_sessionID')?.toString() || '';
