import {
  getLandingPageUrl as getLandingPageUrlFromCookies,
  getReferrerPageUrl as getReferringPageUrlFromCookies,
} from './cookies';
import { GS_DOMAIN } from './constants';

export const getFullLandingPageUrl = () => {
  const url = getLandingPageUrlFromCookies() || window.location.href;

  if (url === '') {
    return '';
  }

  try {
    const urlObject = new URL(url);

    if (urlObject.hostname.toLowerCase().includes(GS_DOMAIN)) {
      return url;
    }
  } catch (err) {
    return `Invalid URL - ${url}`;
  }

  return '';
};

const getReferringPageUrlFromDocument = () => {
  try {
    // eslint-disable-next-line no-restricted-globals
    if (top) {
      // eslint-disable-next-line no-restricted-globals
      const topValue = top.document.referrer;
      if (topValue) {
        return topValue;
      }
    }
  } catch (err) {
    try {
      // eslint-disable-next-line no-restricted-globals
      if (parent) {
        // eslint-disable-next-line no-restricted-globals
        const parentValue = parent.document.referrer;
        if (parentValue) {
          return parentValue;
        }
      }
      // eslint-disable-next-line no-empty
    } catch (parentErr) {}
  }

  const docValue = document.referrer;
  if (docValue) {
    return docValue;
  }

  return window.location.href;
};

export const getFullReferringPageUrl = () => {
  const urlFromCookie = getReferringPageUrlFromCookies();
  const urlFromDocument = getReferringPageUrlFromDocument();
  const url = urlFromCookie || urlFromDocument;

  if (url === '') {
    return '';
  }

  try {
    const urlObject = new URL(url);
    if (urlObject.hostname.toLowerCase().includes(GS_DOMAIN)) {
      return '';
    }
  } catch {
    return `Invalid URL - ${url}`;
  }

  return url;
};
