import { CookieValueTypes } from 'cookies-next';
import React, { useState } from 'react';

import {
  getFullLandingPageUrl,
  getFullReferringPageUrl,
} from '@/helpers/attributionProperties';
import { GtmIdContext } from '@/helpers/contexts';
import { addInternalParamsToUrl } from '@/helpers/urlParams';

interface StoryblokLinkProps {
  addInternalParams?: boolean;
  className?: string;
  link?: Link;
  children: React.ReactNode;
  testId?: string;
}

export const isEmptyLink = (link: Link | undefined) => {
  if (link === undefined) {
    return true;
  }

  const { id, linktype, url } = link;

  if (linktype === 'url' && url.trim() === '') {
    return true;
  }

  if (linktype === 'story' && id === '') {
    return true;
  }

  return false;
};

const buildUrl = (link?: Link) => {
  if (!link) {
    return '';
  }

  if (link.linktype === 'email') {
    return `mailto:${link.url}`;
  }

  if (link.linktype === 'url' && link.url.startsWith('tel:')) {
    return link.url;
  }

  if (link.linktype === 'story') {
    if (!isEmptyLink(link)) {
      const slug = link.cached_url;

      return `[post_build_link_start]${slug}[post_build_link_end]`;
    }

    return '';
  }

  let result = link.url;

  if (link.url.startsWith('#')) {
    return link.url;
  }

  if (link.anchor) {
    result += `#${link.anchor}`;
  }

  return result;
};

const addParamsToLink = (
  link: Link,
  params: {
    gtmId: string;
    referring: CookieValueTypes;
    landingPage: CookieValueTypes;
  },
): Link => {
  if (!link || link.linktype !== 'url' || !link.url) {
    return link;
  }

  const { url } = link;

  return { ...link, url: addInternalParamsToUrl(url, params) };
};

const StoryblokLink = (props: StoryblokLinkProps) => {
  const gtmId = React.useContext(GtmIdContext);
  const { addInternalParams, className, link, children, testId } = props;
  const [decoratedLink, setDecoratedLink] = useState<Link>();

  React.useEffect(() => {
    if (addInternalParams && link) {
      const newLink = addParamsToLink(link, {
        gtmId,
        referring: getFullReferringPageUrl(),
        landingPage: getFullLandingPageUrl(),
      });

      setDecoratedLink(newLink);
    }
  }, [link, gtmId, addInternalParams]);

  return isEmptyLink(link) ? (
    <div className={className}>{children}</div>
  ) : (
    <a
      className={className}
      href={
        addInternalParams ? buildUrl(decoratedLink || link) : buildUrl(link)
      }
      rel="noopener"
      target={link?.target || '_self'}
      data-testid={`StoryblokLink-${testId}`}
    >
      {children}
    </a>
  );
};

export default StoryblokLink;
