import { useContext } from 'react';
import cx from 'classnames';
import { FieldProps, FormikErrors, FormikTouched } from 'formik';
import { ISbRichtext, SbBlokData, storyblokEditable } from '@storyblok/react';

import { CheckboxInput } from '@/components';
import { FormDataType } from '@/helpers/form/formHelpersTypes';
import { ThemeContext } from '../../helpers/contexts';

const FIELD_ID = 'termsAndConditions';

export const FieldData: FormDataType = {
  hubspotId: '',
  id: FIELD_ID,
  type: 'boolean',
  validationType: 'bool',
};

export interface FormTermsAndConditionsFieldBlokProps extends SbBlokData {
  label: string;
  readOnly: boolean;
  formikErrors: FormikErrors<{ [FIELD_ID]: string }>;
  touched: FormikTouched<{ [FIELD_ID]: boolean }>;
  richTextLabel?: ISbRichtext;
}

interface FormTermsAndConditionsFieldProps extends FieldProps {
  blok: FormTermsAndConditionsFieldBlokProps;
}

const FormTermsAndConditionsField = ({
  blok,
  field,
}: FormTermsAndConditionsFieldProps) => {
  const theme = useContext(ThemeContext);
  const { label, formikErrors, touched, readOnly, richTextLabel } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx({ '!text-xs sm:!text-xs lg:!text-xs': theme === 'ideal' })}
    >
      <CheckboxInput
        hubspotId={FieldData.hubspotId}
        error={
          formikErrors[FIELD_ID] && touched[FIELD_ID]
            ? formikErrors[FIELD_ID]
            : ''
        }
        {...field}
        defaultChecked={field.value}
        label={label}
        richTextLabel={richTextLabel}
        disabled={readOnly}
      />
    </div>
  );
};

export default FormTermsAndConditionsField;
