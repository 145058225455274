import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

import StoryblokLink from '../../components/StoryblokLink/StoryblokLink';

export interface MenuLinkBlokProps extends SbBlokData {
  label: string;
  link: Link;
}

export interface MenuLinkProps {
  blok: MenuLinkBlokProps;
  onClick?: () => void;
}

const MenuLink = ({ blok, onClick }: MenuLinkProps) => {
  const { label, link } = blok;

  return (
    <button
      {...storyblokEditable(blok)}
      onClick={onClick}
      className="content-center"
      type="button"
    >
      <StoryblokLink
        link={link}
        className={cx(
          'block content-center text-center font-worksans',
          'text-xl font-bold lg:text-base lg:font-medium',
          'text-GSblue/500 lg:text-GSbase/black',
          'py-2 lg:px-4',
        )}
      >
        {label}
      </StoryblokLink>
    </button>
  );
};

export default MenuLink;
