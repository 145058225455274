import { useEffect, useState } from 'react';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

import ButtonComponent, { ButtonProps, Size } from '@/components/Button/Button';

export interface StaticCtaButtonBlokProps extends SbBlokData {
  addParamsToUrl?: boolean;
  label: string;
  link: Link;
  background?: 'darkBG' | 'lightBG';
}

interface StaticCtaButtonProps extends ButtonProps {
  blok: StaticCtaButtonBlokProps;
  size?: Size;
  type?: 'primary' | 'secondary' | 'textLink';
  loading?: boolean;
}

const StaticCtaButton = ({ blok, size, ...rest }: StaticCtaButtonProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const { addParamsToUrl, background = 'lightBG', label, link } = blok;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(!entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
      },
    );

    const heroSection = document.getElementById('hero-section');

    if (heroSection) {
      observer.observe(heroSection);
    }

    return () => {
      if (heroSection) {
        observer.unobserve(heroSection);
      }
    };
  }, []);

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx(
        'sticky sm:hidden bottom-0 w-full max-w-full sm:w-auto z-50',
        { hidden: !isVisible },
      )}
    >
      <div className="w-full flex flex-col gap-1 sm:gap-2 lg:gap-3 items-center max-w-full sm:w-auto">
        <ButtonComponent
          className="!text-lg font-medium !py-4 !rounded-none"
          addInternalParams={addParamsToUrl}
          background={background}
          label={label}
          link={link}
          testId={`CtaButton-${blok._uid}`}
          size={size}
          {...rest}
        />
      </div>
    </div>
  );
};

export default StaticCtaButton;
