import { ChangeEventHandler, useContext } from 'react';
import cx from 'classnames';
import { ISbRichtext, renderRichText } from '@storyblok/react';
import mpcRichTextSchema from './richTextSchema';
import { ThemeContext } from '../../helpers/contexts';

export interface CheckboxInputProps {
  checked?: boolean;
  error?: string;
  name?: string;
  hubspotId?: string;
  label: string;
  disabled?: boolean;
  defaultChecked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  value?: string;
  richTextLabel?: ISbRichtext;
}

const CheckboxInput = (field: CheckboxInputProps) => {
  const {
    checked,
    defaultChecked,
    disabled,
    error,
    label,
    name,
    onChange,
    value,
  } = field;
  const theme = useContext(ThemeContext);
  const { hubspotId, richTextLabel, ...fieldRest } = field;

  const hubspotValue = () => {
    if (hubspotId === 'marketing_opt_in__instapage_') {
      return value ? 'Yes' : 'No';
    }
    return value ? 'true' : '';
  };

  return (
    <div className="w-full flex flex-col gap-y-1">
      {hubspotId && (
        <>
          <label className="hidden" htmlFor={hubspotId}>
            {hubspotId}
          </label>
          <input
            name={hubspotId}
            className="hidden"
            value={hubspotValue()}
            readOnly
          />
        </>
      )}
      <div className="w-full flex gap-2">
        <input
          className="
            peer relative appearance-none w-4 h-4 shrink-0 border-2 border-GSblue/200 rounded-sm mt-0.5 bg-GSbase/white
            focus:outline-none focus:ring-offset-0 focus:ring-2 focus:ring-GSblue/100
            checked:bg-GSblue/500 checked:border-0
            disabled:border-GSsteel/400 disabled:bg-GSsteel/400
          "
          type="checkbox"
          id={name}
          {...fieldRest}
          disabled={disabled}
          defaultChecked={defaultChecked}
          checked={checked}
          onChange={onChange}
        />
        <svg
          className="absolute w-4 h-4 pointer-events-none peer-checked:stroke-GSbase/white mt-0.5"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M3 8.16667L6.75 11.0833L13 4" strokeWidth="2" />
        </svg>
        <label
          htmlFor={name}
          className={cx('text-GSdeep/900', {
            'text-GSsteel/500': disabled === true,
            'text-sm': theme !== 'ideal',
            'text-xs': theme === 'ideal',
          })}
        >
          {label ||
            (richTextLabel && (
              <div
                className="break-words min-w-0"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: renderRichText(richTextLabel, {
                    schema: mpcRichTextSchema,
                  }),
                }}
              />
            ))}
        </label>
      </div>
      {error && (
        <div className="text-xs text-GSfunctional/error font-medium">
          {error}
        </div>
      )}
    </div>
  );
};
export default CheckboxInput;
