import { createContext } from 'react';

export const GtmIdContext = createContext('');

type SSGDataContextType = {
  alternateUrls?: MpcAlternateUrls;
  formLabels?: Record<string, string>;
  countriesByCode?: Record<string, string>;
};

export const SSGDataContext = createContext<SSGDataContextType>({});

export const ThemeContext = createContext<'default' | 'ideal' | ''>('default');
