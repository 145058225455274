import { RichTextSchema } from '@storyblok/react';
import cloneDeep from 'clone-deep';

const mpcRichTextSchema = cloneDeep(RichTextSchema);

mpcRichTextSchema.marks.link = (node: ISbNode) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const schema: any = RichTextSchema.marks.link(node);

  schema.tag[0].attrs.class =
    'text-GSbase/black hover:text-GSbase/black underline';

  return schema;
};

mpcRichTextSchema.marks.textStyle = () => {
  // we do not support changing text color
  return {
    tag: '',
  };
};

export default mpcRichTextSchema;
