/* eslint-disable jsx-a11y/control-has-associated-label */
import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

export interface HeaderLogoBlokProps extends SbBlokData {
  image: Asset;
  link: Link;
  placement: 'left' | 'center';
  mobilePlacement: 'default' | 'center';
}

interface HeaderLogoProps {
  blok: HeaderLogoBlokProps;
  disablePlacement?: boolean;
}

const HeaderLogo = ({ blok, disablePlacement }: HeaderLogoProps) => {
  const { image, link, placement, mobilePlacement } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx('flex items-center shrink-0', {
        'w-full justify-center': placement === 'center' && !disablePlacement,
        'justify-start':
          placement === 'left' &&
          !disablePlacement &&
          mobilePlacement === 'default',
        'w-full justify-center sm:justify-start':
          placement === 'left' &&
          !disablePlacement &&
          mobilePlacement === 'center',
      })}
    >
      <a className={cx({ 'bg-gray-400': !image.filename })} href={link.url}>
        <StoryblokImage
          blok={image}
          fallbackSrc="/assets/image.svg"
          className="h-8 sm:h-10 lg:h-12"
          lazyLoading={false}
        />
      </a>
    </div>
  );
};

export default HeaderLogo;
