import { useContext } from 'react';
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';

import { CtaButtonBlokProps } from '@/blocks/CtaButton/CtaButton';
import { PageContainer } from '@/components';
import BenefitContent, {
  BenefitContentBlokProps,
} from '../BenefitContent/BenefitContent';
import TitleBlok, { TitleBlokProps } from '../Title/Title';
import { CtaLinkBlokProps } from '../CtaLink/CtaLink';
import { ThemeContext } from '../../helpers/contexts';

export interface BenefitSectionBlokProps extends SbBlokData {
  content: Array<BenefitContentBlokProps>;
  cta: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
  title: Array<TitleBlokProps>;
  id?: string;
  backgroundColor: 'GSbase/white' | 'GSsteel/100';
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

interface BenefitSectionProps {
  blok: BenefitSectionBlokProps;
}

const BenefitSection = ({ blok }: BenefitSectionProps) => {
  const theme = useContext(ThemeContext);
  const {
    backgroundColor,
    cta = [],
    content = [],
    id,
    title: [title] = [],
    visibility,
    visibilityOnMobile,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx({
        'bg-GSbase/white': backgroundColor === 'GSbase/white',
        'bg-GSsteel/100': backgroundColor === 'GSsteel/100',
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer
        className={cx({ '!py-10 sm:!py-16 lg:!py-20': theme === 'ideal' })}
      >
        {title && (
          <div className="w-full">
            <TitleBlok blok={title} headingLevel="h2" />
          </div>
        )}
        <div
          className={cx('flex flex-col w-full gap-2 sm:gap-4 lg:gap-8', {
            '!gap-12 sm:!gap-16 lg:!gap-24': theme === 'ideal',
          })}
        >
          {content.map((nestedBlok) => (
            <BenefitContent
              blok={nestedBlok}
              key={nestedBlok._uid}
              headingsLevel={title ? 'h3' : 'h2'}
              theme={theme}
            />
          ))}
        </div>
        {cta && cta.length > 0 && (
          <div className="w-full flex flex-col sm:flex-row gap-2 sm:gap-8 justify-center">
            {cta.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </div>
        )}
      </PageContainer>
    </section>
  );
};

export default BenefitSection;
