import { useContext } from 'react';
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import FeatureCardBlock, {
  FeatureCardBlokProps,
} from '@/blocks/FeatureCard/FeatureCard';
import TitleBlok, { TitleBlokProps } from '@/blocks/Title/Title';
import { CtaLinkBlokProps } from '@/blocks/CtaLink/CtaLink';
import { CtaButtonBlokProps } from '@/blocks/CtaButton/CtaButton';
import PageContainer from '@/components/PageContainer/PageContainer';
import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import { ThemeContext } from '../../helpers/contexts';

export interface FeaturesSectionBlokProps extends SbBlokData {
  cta: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
  features: Array<FeatureCardBlokProps>;
  id?: string;
  title: Array<TitleBlokProps>;
  layoutSize: 'narrow' | 'default' | 'wide';
  image: Asset;
  ctaPlacement: 'aboveCards' | 'belowCards';
  imagePlacement: 'beforeCards' | 'afterCards';
  imagePosition?:
    | 'bottom'
    | 'center'
    | 'left'
    | 'left bottom'
    | 'left top'
    | 'right'
    | 'right bottom'
    | 'right top'
    | 'top';
  backgroundColor: 'none' | 'GSparchment/200' | 'GSblue/50';
  headerColorVariant: 'GSpurple/500' | 'GSgreen/500' | 'GSorange/500';
  layout: 'cardHeaderText' | 'cardHeaderImage';
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

interface FeaturesSectionProps {
  blok: FeaturesSectionBlokProps;
}

const FeaturesSection = ({ blok }: FeaturesSectionProps) => {
  const theme = useContext(ThemeContext);
  const {
    cta: [cta] = [],
    features,
    id,
    title: [title] = [],
    layoutSize = 'default',
    image,
    ctaPlacement = 'belowCards',
    imagePlacement = 'beforeCards',
    imagePosition = 'center',
    backgroundColor = 'none',
    headerColorVariant = 'GSpurple/500',
    layout = 'cardHeaderText',
    visibility,
    visibilityOnMobile,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('w-full', {
        'bg-GSparchment/200': backgroundColor === 'GSparchment/200',
        'bg-GSblue/50': backgroundColor === 'GSblue/50',
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer
        className={cx({ '!py-10 sm:!py-12 lg:!py-16': theme === 'ideal' })}
      >
        <div
          className={cx(
            'w-full grid grid-cols-6 sm:grid-cols-12 gap-x-2 gap-y-4 sm:gap-x-4 sm:gap-y-6 lg:gap-x-8 lg:gap-y-10',
            { '!gap-y-10 sm:!gap-y-14': blok.theme === 'ideal' },
          )}
        >
          {title && (
            <div
              className={cx('col-span-full', {
                'lg:col-span-10 lg:col-start-2':
                  (features.length !== 4 && layoutSize !== 'wide') ||
                  (features.length === 4 && layoutSize === 'narrow'),
                'lg:col-span-12':
                  features.length !== 4 && layoutSize === 'wide',
              })}
            >
              <TitleBlok blok={title} headingLevel="h2" theme={theme} />
            </div>
          )}
          {cta && ctaPlacement === 'aboveCards' && (
            <div className="col-span-full sm:flex sm:justify-center">
              <StoryblokComponent blok={cta} />
            </div>
          )}
          <div
            className={cx(
              'grid gap-2 sm:gap-4 lg:gap-8 col-span-6 grid-cols-1 sm:col-span-12',
              {
                'sm:grid-cols-3 lg:col-span-10 lg:col-start-2':
                  features.length !== 4 && layoutSize !== 'wide',
                'sm:grid-cols-3 lg:col-span-12':
                  features.length !== 4 && layoutSize === 'wide',
                'sm:grid-cols-4 lg:col-span-12':
                  features.length === 4 && layoutSize !== 'narrow',
                'sm:grid-cols-2 lg:col-span-10 lg:col-start-2 ':
                  features.length === 4 && layoutSize === 'narrow',
              },
            )}
          >
            {image && image.filename && features.length === 5 && (
              <div
                className={cx('rounded overflow-hidden relative', {
                  'order-last': imagePlacement === 'afterCards',
                })}
              >
                <StoryblokImage
                  className={cx('object-cover w-full absolute h-full', {
                    'object-bottom': imagePosition === 'bottom',
                    'object-center': imagePosition === 'center',
                    'object-left': imagePosition === 'left',
                    'object-left-bottom': imagePosition === 'left bottom',
                    'object-left-top': imagePosition === 'left top',
                    'object-right': imagePosition === 'right',
                    'object-right-bottom': imagePosition === 'right bottom',
                    'object-right-top': imagePosition === 'right top',
                    'object-top': imagePosition === 'top',
                  })}
                  blok={image}
                />
              </div>
            )}
            {theme !== 'ideal' &&
              features.map((featureBlok: FeatureCardBlokProps) => (
                <FeatureCardBlock
                  blok={featureBlok}
                  key={featureBlok._uid}
                  headerColorVariant={headerColorVariant}
                  imageAsHeader={layout === 'cardHeaderImage'}
                  theme={theme}
                />
              ))}
            {theme === 'ideal' && (
              <div className="w-full col-span-full gap-10 flex flex-col">
                <div className="w-full flex">
                  <Swiper
                    slidesPerView={1.5}
                    spaceBetween={24}
                    pagination={{
                      clickable: true,
                      el: `.swiper-pagination-${blok._uid}`,
                    }}
                    modules={[Pagination]}
                    breakpoints={{
                      640: {
                        slidesPerView: 3,
                        spaceBetween: 32,
                      },
                    }}
                  >
                    {features.map((featureBlok: FeatureCardBlokProps) => (
                      <SwiperSlide key={featureBlok._uid}>
                        <FeatureCardBlock
                          blok={featureBlok}
                          headerColorVariant={headerColorVariant}
                          imageAsHeader={layout === 'cardHeaderImage'}
                          theme={theme}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                <div
                  className={`swiper-pagination-${blok._uid} !static flex justify-center`}
                />
              </div>
            )}
          </div>
          {cta && ctaPlacement === 'belowCards' && (
            <div className="col-span-full sm:flex sm:justify-center">
              <StoryblokComponent blok={cta} />
            </div>
          )}
        </div>
      </PageContainer>
    </section>
  );
};

export default FeaturesSection;
