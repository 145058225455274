import React from 'react';
import { setCookie } from 'cookies-next';
import {
  ISbStory,
  StoryblokComponent,
  ISbStoryData,
  useStoryblokState,
  setComponents,
} from '@storyblok/react';
import Head from 'next/head';

import { getPreviewPaths, getPreviewStory } from '@/utils/routing/preview';
import {
  getStaticBuildPaths,
  getStaticBuildStory,
} from '@/utils/routing/staticBuild';
import {
  getAlternateUrls,
  getAlternateUrlsFromStory,
  getCanonicalUrlFromRealSlug,
  getLanguageFromStorySlug,
} from '@/utils/locale';
import { SSGDataContext } from '@/helpers/contexts';
import { getCountriesByCode, getFormLabels } from '@/helpers/datasources/cache';
import { resolveRelations } from '@/utils/routing/relations';
import getStructuredDataSchema from '@/utils/structured-data/schema';
import { getUsedComponentsNames } from '@/utils/storyblok/story';
import { getComponentsMap } from '@/utils/storyblok/components';
import {
  getFullLandingPageUrl,
  getFullReferringPageUrl,
} from '@/helpers/attributionProperties';

export default function PageTemplate({
  alternateUrls,
  canonicalUrl,
  countriesByCode,
  formLabels,
  language,
  story: initialStory,
}: {
  alternateUrls: MpcAlternateUrls;
  canonicalUrl: string;
  countriesByCode?: Record<string, string>;
  formLabels?: Record<string, string>;
  language: string;
  story: ISbStoryData;
}) {
  React.useEffect(() => {
    const referrer = getFullReferringPageUrl();
    setCookie('referring', referrer);

    const landingPage = getFullLandingPageUrl();
    setCookie('landingPage', landingPage);
  }, []);

  setComponents(getComponentsMap(getUsedComponentsNames(initialStory)));

  const story =
    process.env.staticPagesBuild === 'true'
      ? initialStory
      : useStoryblokState(initialStory, {
          resolveRelations,
          preventClicks: true,
        });

  if (!story?.content) {
    return <div>Loading...</div>;
  }

  const structuredData = React.useMemo(
    () => getStructuredDataSchema(story.content),
    [story],
  );

  return (
    <SSGDataContext.Provider
      value={{ alternateUrls, countriesByCode, formLabels }}
    >
      <Head>
        {structuredData &&
          structuredData.map((data, index) => (
            <script
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              type="application/ld+json"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: data }}
            />
          ))}
      </Head>
      <StoryblokComponent
        blok={{
          ...story.content,
          _mpc: {
            alternateUrls,
            canonicalUrl,
            language,
          },
        }}
      />
    </SSGDataContext.Provider>
  );
}

export async function getStaticProps({
  params: { slug: splitSlug },
  preview,
}: {
  params: { slug: string[] };
  preview: boolean;
}) {
  let story: ISbStory | undefined;

  try {
    if (process.env.staticPagesBuild === 'true') {
      story = await getStaticBuildStory(splitSlug);
    } else {
      story = await getPreviewStory(splitSlug, preview);
    }

    const language = story
      ? getLanguageFromStorySlug(story.data.story.full_slug)
      : 'en';

    let formLabels = null;
    let countriesByCode = null;
    let alternateUrls = null;
    if (process.env.staticPagesBuild === 'true') {
      countriesByCode = await getCountriesByCode();
      formLabels = await getFormLabels(language);
      alternateUrls = await getAlternateUrls(splitSlug);
    } else {
      alternateUrls = getAlternateUrlsFromStory(story);
    }

    return {
      props: {
        canonicalUrl: getCanonicalUrlFromRealSlug(splitSlug.join('/')),
        alternateUrls,
        countriesByCode,
        formLabels,
        language,
        story: story ? story.data.story : false,
        key: story ? story.data.story.id : false,
        preview:
          process.env.staticPagesBuild === 'true' ? false : Boolean(preview),
      },
      revalidate: process.env.staticPagesBuild === 'true' ? false : 3600,
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Story not found:', splitSlug);
    return {
      notFound: true,
    };
  }
}

export async function getStaticPaths() {
  let paths: { params: { slug: string[] } }[] = [];

  if (process.env.staticPagesBuild === 'true') {
    paths = await getStaticBuildPaths();
  } else {
    paths = await getPreviewPaths();
  }

  return {
    paths,
    fallback: process.env.staticPagesBuild === 'true' ? false : 'blocking',
  };
}
