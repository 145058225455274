import {
  ISbRichtext,
  renderRichText,
  SbBlokData,
  storyblokEditable,
} from '@storyblok/react';
import parse from 'html-react-parser';

import mpcRichTextSchema from './richTextSchema';

export interface FormStaticTextFieldBlokProps extends SbBlokData {
  content: ISbRichtext;
}

interface FormStaticTextFieldProps {
  blok: FormStaticTextFieldBlokProps;
}

const FormStaticTextField = ({ blok }: FormStaticTextFieldProps) => {
  const { content } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="font-worksans text-xs font-normal text-GSbase/black"
    >
      {parse(
        renderRichText(content, {
          schema: mpcRichTextSchema,
        }),
      )}
    </div>
  );
};

export default FormStaticTextField;
