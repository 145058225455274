import { useContext } from 'react';
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';

import { TitleBlokProps } from '../Title/Title';
import PageContainer from '../../components/PageContainer/PageContainer';
import { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import { CtaLinkBlokProps } from '../CtaLink/CtaLink';
import FaqSectionGrid from './FaqSectionGrid';
import FaqSectionAccordion from './FaqSectionAccordion';
import { FaqSectionItemBlokProps } from '../FaqSectionItem/types';
import { ThemeContext } from '../../helpers/contexts';

export interface FaqSectionBlokProps extends SbBlokData {
  backgroundColor?: 'GSparchment/200' | 'GSbase/white' | '';
  contentWidth?: 'narrow' | 'wide';
  cta?: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
  id?: string;
  items: Array<FaqSectionItemBlokProps>;
  layout?: 'oneColumn' | 'twoColumns' | '';
  title: Array<TitleBlokProps>;
  topPadding?: 'default' | 'none' | '';
  type?: 'accordion' | 'grid' | '';
  visibility?: 'enabled' | 'disabled' | '';
  visibilityOnMobile?: 'enabled' | 'disabled' | '';
}

export interface FaqProps {
  blok: FaqSectionBlokProps;
}

const FaqSection = ({ blok }: FaqProps) => {
  const theme = useContext(ThemeContext);
  const {
    backgroundColor,
    cta = [],
    id,
    items,
    topPadding = 'default',
    type = 'grid',
    visibility = 'enabled',
    visibilityOnMobile = 'enabled',
  } = blok;

  const filteredItems = items.filter((item) => item.visibility !== 'disabled');

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx(
        'w-full text-GSdeep/900 scroll-mt-16 sm:scroll-mt-20 lg:scroll-mt-36',
        {
          'bg-GSbase/white':
            !backgroundColor || backgroundColor === 'GSbase/white',
          'bg-GSparchment/200': backgroundColor === 'GSparchment/200',
          'hidden sm:block':
            (visibility === 'enabled' || visibility === '') &&
            visibilityOnMobile === 'disabled',
          hidden: visibility === 'disabled',
        },
      )}
      id={id}
    >
      <PageContainer
        className={cx({
          '!pt-0': topPadding === 'none',
          '!gap-y-8 sm:!gap-y-14 !py-10 sm:!py-12 lg:!py-16':
            blok.theme === 'ideal',
        })}
      >
        {type === 'accordion' ? (
          <FaqSectionAccordion
            blok={{ ...blok, items: filteredItems }}
            theme={theme}
          />
        ) : (
          <FaqSectionGrid blok={{ ...blok, items: filteredItems }} />
        )}
        {cta && cta.length > 0 && (
          <div
            className={cx(
              'w-full flex flex-col sm:flex-row gap-2 sm:gap-8 justify-center',
              { 'hidden sm:flex': theme === 'ideal' },
            )}
          >
            {cta.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </div>
        )}
      </PageContainer>
    </section>
  );
};

export default FaqSection;
