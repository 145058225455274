import { SbBlokData } from '@storyblok/react';
import React from 'react';

export interface VideoInformationTestimonialBlokProps extends SbBlokData {
  description?: string;
  membershipDuration?: string;
  name?: string;
}

export interface VideoInformationTestimonialProps {
  blok: VideoInformationTestimonialBlokProps;
}

const VideoInformationTestimonial = ({
  blok,
}: VideoInformationTestimonialProps) => {
  const { description, membershipDuration, name } = blok;

  return (
    <div className="flex flex-col items-center text-center gap-y-0 lg:gap-y-1 text-GSbase/black">
      <span className="font-bold text-base lg:text-lg">{name}</span>
      <span className="font-normal text-sm lg:text-base ">{description}</span>
      <span className="font-normal text-sm lg:text-base">
        {membershipDuration}
      </span>
    </div>
  );
};

export default VideoInformationTestimonial;
