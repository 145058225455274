import { useContext, useState } from 'react';
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';

import { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import PageContainer from '../../components/PageContainer/PageContainer';
import HeaderLogoBlok, { HeaderLogoBlokProps } from '../HeaderLogo/HeaderLogo';
import HeaderPartnerLogoBlok, {
  HeaderPartnerLogoBlokProps,
} from '../HeaderPartnerLogo/HeaderPartnerLogo';
import HeaderPhoneNumberBlok, {
  HeaderPhoneNumberBlokProps,
} from '../HeaderPhoneNumber/HeaderPhoneNumber';
import useResponsive from '../../helpers/layout/useResponsive';
import { MenuIcon } from '../WebsiteHeader/icons';
import MobileMenuOverlay from '../../components/MobileMenuOverlay/MobileMenuOverlay';
import { ThemeContext } from '../../helpers/contexts';

export interface HeaderBlokProps extends SbBlokData {
  actions?: Array<CtaButtonBlokProps>;
  background: 'opaque' | 'transparent';
  layout: 'logo' | 'logo_phone' | 'logo_actions' | 'logo_partner';
  logo: Array<HeaderLogoBlokProps>;
  menuItems?: Array<SbBlokData>;
  mobileMenuHeader?: string;
  partnerLogo: Array<HeaderPartnerLogoBlokProps>;
  phoneNumber: Array<HeaderPhoneNumberBlokProps>;
}

interface HeaderProps {
  blok: HeaderBlokProps;
}

const Header = ({ blok }: HeaderProps) => {
  const theme = useContext(ThemeContext);
  const { isMobile } = useResponsive();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const {
    actions,
    background,
    layout,
    logo: [logoBlok],
    menuItems = [],
    mobileMenuHeader = '',
    partnerLogo: [partnerLogoBlok],
    phoneNumber: [phoneNumberBlok],
  } = blok;

  return (
    <nav
      {...storyblokEditable(blok)}
      className={cx('w-full', {
        'bg-GSbase/white': background === 'opaque',
        'bg-transparent': background === 'transparent',
      })}
    >
      <PageContainer
        className={cx('!py-2 sm:!py-4', { 'lg:!py-2': theme === 'ideal' })}
      >
        <div className="flex flex-row w-full gap-2 sm:gap-4 lg:gap-8 text-GSbase/black items-center">
          {logoBlok && (
            <HeaderLogoBlok
              blok={logoBlok}
              disablePlacement={layout !== 'logo'}
            />
          )}
          {menuItems.length > 0 && (
            <div className="hidden lg:flex gap-4">
              {menuItems.map((item) => (
                <StoryblokComponent blok={item} key={item._uid} />
              ))}
            </div>
          )}
          {layout === 'logo_phone' && phoneNumberBlok && (
            <HeaderPhoneNumberBlok blok={phoneNumberBlok} />
          )}
          {layout === 'logo_actions' && actions && actions.length > 0 && (
            <div className="ml-auto flex gap-x-4">
              {actions.map((ctaBlok) => (
                <StoryblokComponent
                  blok={ctaBlok}
                  size={!isMobile ? 'btn-SM' : 'btn-XS'}
                  key={ctaBlok._uid}
                />
              ))}
            </div>
          )}
          {layout === 'logo_partner' && partnerLogoBlok && (
            <HeaderPartnerLogoBlok blok={partnerLogoBlok} />
          )}
          {menuItems.length > 0 && (
            <div
              className="select-none flex items-center ml-auto cursor-pointer lg:hidden"
              onClick={() => setMobileMenuOpen(true)}
            >
              <MenuIcon />
            </div>
          )}
        </div>
      </PageContainer>
      <MobileMenuOverlay
        isOpen={mobileMenuOpen}
        label={mobileMenuHeader}
        menuItems={menuItems}
        onClose={() => setMobileMenuOpen(false)}
      />
    </nav>
  );
};

export default Header;
